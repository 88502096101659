// Generated by ReScript, PLEASE EDIT WITH CARE

import * as StackedLayoutTsx from "./stacked-layout.tsx";

var make = StackedLayoutTsx.StackedLayout;

export {
  make ,
}
/* make Not a pure module */
