// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SidebarTsx from "./sidebar.tsx";

var make = SidebarTsx.Sidebar;

var Sidebar = {
  make: make
};

var make$1 = SidebarTsx.SidebarHeader;

var SidebarHeader = {
  make: make$1
};

var make$2 = SidebarTsx.SidebarBody;

var SidebarBody = {
  make: make$2
};

var make$3 = SidebarTsx.SidebarSection;

var SidebarSection = {
  make: make$3
};

var make$4 = SidebarTsx.SidebarItem;

var SidebarItem = {
  make: make$4
};

var make$5 = SidebarTsx.SidebarLabel;

var SidebarLabel = {
  make: make$5
};

export {
  Sidebar ,
  SidebarHeader ,
  SidebarBody ,
  SidebarSection ,
  SidebarItem ,
  SidebarLabel ,
}
/* make Not a pure module */
